@use 'scss/tokens' as *;

.tooltip-wrapper {
  position: absolute;
  transform: translateY(8px);

  .tooltip-body {
    width: 312px;
    max-height: 350px;
    overflow-y: scroll;
    background-color: $color-background;
    border: 1px solid $color-stroke;
    border-radius: 2px;
    padding: $spacing-2;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
  }

  .comment-item {
    margin-bottom: $spacing-3;

    .comment-author {
      font-size: $font-size-sm;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .comment-text {
      font-size: $font-size-sm;
      font-weight: 500;
      word-break: break-word;
    }

    .comment-date {
      font-size: $font-size-xs;
      color: rgba($color-primary, 0.5);
    }
  }
}
