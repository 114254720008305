@use "scss/tokens" as *;

.container {
  width: 360px;
  position: absolute;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  right: 0;
  top: 20px;

  &:before {
    $triangleHeight: 10px;
    content: '';
    width: 0;
    height: 0;

    border-left: $triangleHeight solid transparent;
    border-right: $triangleHeight solid transparent;

    border-bottom: $triangleHeight solid $color-background;
    position: absolute;
    top: -#{$triangleHeight - 1px};
    right: 15px;
  }
}

.body {
  max-height: 300px;
  overflow: auto;

  & > div {
    background-color: $color-white;
    padding: $spacing-2;
    font-size: $font-size-xs;

    &.unread {
      cursor: pointer;

      .dot {
        background-color: $color-red;
      }
    }

    &:nth-child(even) {
      background-color: $color-background;
    }

    .dot {
      background-color: $color-stroke;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: $spacing-1;
    }

    &.disabled {
      background-color: $color-gray;
      pointer-events: none;
    }
  }
}

.header {
  background-color: $color-background;
  padding: $spacing-2;
  font-size: $font-size-xs;
  border-radius: 2px 2px 0 0;
  border: 1px solid $color-stroke;
}

.loader-container {
  background-color: $color-white;
  padding: $spacing-1;
}

.notification-icon {
  position: relative;

  color:$color-dark-blue;

  @keyframes notificationButtonKeyframe {
    0% {
      color: $color-dark-blue;
      //scale: 100%;
    }
    50% {
      color: $color-orange;
      //scale: 150%;
    }
    100% {
      color: $color-dark-blue;
      //scale: 100%;
    }
  }

  &.unread {
    &:after {
      content: '';
      position: absolute;
      background-color: $color-red;
      top: 0;
      right: 3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &.animate {
    animation: notificationButtonKeyframe ease-in-out 1s infinite;
  }
}
