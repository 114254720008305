@use 'scss/tokens' as *;

.auth-page {
  height: 100%;
  background-color: $color-gray;
}

.auth-form {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
