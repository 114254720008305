@use 'scss/tokens' as *;

.card {
  border: 1px solid $color-stroke;
  display: flex;
  min-width: 0;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  padding: $spacing-3;

  &:not(.light) {
    background-color: $color-background;
  }

  &.hoverable {
    cursor: pointer;
  }

  .card-body {
    flex: 1 1 auto;
  }
}
