@use 'scss/tokens' as *;

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.notification {
  max-width: 388px;
  max-height: 200px;
  min-height: 64px;
  overflow: hidden;
  padding: $spacing-1 $spacing-5 $spacing-1 $spacing-1-5;
  z-index: 99;
  font-weight: bold;
  position: relative;
  background-color: $color-white;
  /* Stroke */

  border: 1px solid $color-stroke;
  border-left: 4px solid;

  /* shadow */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  transition: transform 0.3s ease-out;

  &.info {
    border-left-color: $notification-info;
    .alert-icon {
      color: $notification-info;
    }
  }

  &.success {
    border-left-color: $notification-success;
    .alert-icon {
      color: $notification-success;
    }
  }

  &.warning {
    border-left-color: $notification-warning;
    .alertI-ion {
      color: $notification-warning;
    }
  }

  &.error {
    border-left-color: $notification-error;
    .alert-icon {
      color: $notification-error;
    }
  }

  &.slideIn {
    animation-name: slideIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  &.slideOut {
    transform: translateX(150%);
    flex: 0;
  }

  .close-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }

  .text {
    font-size: $font-size-xs;
    font-weight: 400;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}


.container {
  overflow: hidden;
  max-height: 200px;
  transition: max-height 0.3s ease-out;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &.shrink {
    max-height: 0;
  }
}
