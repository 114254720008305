@use 'scss/tokens' as *;

.sidebar-header {
  flex: 0 0 auto;
}

.sidebar-content {
  flex: 1 1 auto;
  overflow-y: auto;
  margin: 20px $spacing-3 0;

  &.toc-sidebar {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
}

.deactivation-status-alert {
  position: fixed;
  width: 312px;
  padding: $spacing-1-5 $spacing-2;
  bottom: $spacing-3;
  right: $spacing-3;

  div:first-child {
    padding-right: $spacing-2;
  }
}
