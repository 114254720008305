.progress {
  $thumbColor: #031E50;
  $textColor: #031E50;

  $trackBg: rgba(#031E50,.1);
  $trackBgActiveBg: #031E50;
  $trackBgActiveBgHoveredColor: #FF5C35;
  $height:4px;


  width: 100%;
  position: relative;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  appearance: none;
  -moz-appearance: none;
  background: transparent; /* Otherwise white in Chrome */

  @mixin showThumb {
    &::-webkit-slider-thumb {
      visibility: visible;
    }
    &::-moz-range-thumb{
      visibility: visible;
    }
    &::-ms-thumb {
      visibility: visible;
    }
  }


  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: var(--seek-before-width);
    height: $height;
    background-color: $trackBgActiveBg;
    cursor: pointer;
  }

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &:active {
    &:before {
      background-color: $trackBgActiveBgHoveredColor;
    }
    &::-moz-range-progress {
      background-color: $trackBgActiveBgHoveredColor;
    }
    @include showThumb;
  }

  &:hover {
    @include showThumb;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  // Thumb
  @mixin thumb {
    $thumbSize:8px;
    border: 1px solid $thumbColor;
    height: $thumbSize;
    width: $thumbSize;
    cursor: pointer;
    z-index: 1;
    background-color: $thumbColor;
    border-radius: 2px;
    visibility: hidden;
    position: absolute;
    left: calc(var(--seek-before-width) - #{$thumbSize}/2);
  }

  /* Special styling for WebKit/Blink */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -2px;
    @include thumb;
  }

  /* All the same stuff for Firefox */
  &::-moz-range-thumb{
    @include thumb;
  }

  &::-moz-range-progress {
    height: $height;
    background-color: $trackBgActiveBg;
    cursor: pointer;
  }

  /* IE */
  &::-ms-thumb {
    @include thumb;
  }

  @mixin track {
    width: 100%;
    height: $height;
    cursor: pointer;
    background: $trackBg;
  }

  // Track
  &::-webkit-slider-runnable-track {
    @include track;
  }

  &::-moz-range-track {
    @include track;
  }

  &::-ms-track {
    @include track;

    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $trackBg;
    border: 0.2px solid $trackBg;
    border-radius: 2.6px;
  }

  &:focus::-ms-fill-lower {
    background: $trackBg;
  }

  &::-ms-fill-upper {
    background: $trackBg;
    border-radius: 2.6px;
  }
}
