@use "scss/tokens" as *;

.container {
  padding: 32px!important;
  padding-top: 64px!important;
}

.header-select {
  width: 140px;
}

.header-select-preview {
  text-align: start !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm-body {
  font-size: $font-size-sm;
}

.overall-accuracy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-radius: 4px;
  background-color: $color-white;

  .subtitle {
    color: $color-gray-1;
  }
}

.title {
  font-size: 20px;
  line-height: 32px;
}

.preprocessed-tags-stats-container {
  margin: 24px 0;
}

.preprocessed-tags-stats-list {
  display: flex;
  gap: 20px;
  margin-top: 8px;
}

.preprocessed-tags-stats-item {
  display: flex;
  align-items: center;
  gap: 8px;

  &.count {
    color: $color-gray-1;
    font-size: 14px;
  }
}

.point {
  &.ai-generated {
    color: $color-blue-1;
  }

  &.deleted {
    color: $color-red;
  }

  &.edited {
    color: $color-yellow;
  }
}

.something-went-wrong-message-placeholder {
  margin: 24px 0;
  padding: 24px;
  border-radius: 4px;
  line-height: 32px;
  background-color: $color-white;
  text-align: center;
}
