@use 'scss/tokens' as *;

.card {
  width: 330px;
  height: 100%;
  min-height: 250px;
  position: relative;

  $width: 26px;

  .status {
    position: absolute;
    top: 0;
    right: 22px;
    width: $width;
    height: 32px;


    &.pending {
      background-color: rgba($label-pending, .5);

      &:before {
        background-color: $label-pending;
      }
    }

    &.declined {
      background-color: rgba($label-declined, .5);

      &:before {
        background-color: $label-declined;
      }
    }

    &.new_project {
      background-color: rgba($label-new-project, .5);

      &:before {
        background-color: $label-new-project;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: $width/2;
      width: $width/2;
      height: 100%;
    }

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      bottom: 0;
      border-left: $width/2 solid transparent;
      border-right: $width/2 solid transparent;

      border-bottom: 10px solid $color-background;
    }
  }

  .declined-view {
    border: 2px solid #D7DAE1;
    padding: $spacing-1;
  }
}
