@use 'scss/tokens' as *;

.textarea {
  background-color: $color-white;
  width: 100%;
  border: 1px solid $color-stroke;
  border-radius: 2px;
  font-family: $font-family-primary;
  padding: 12px;
  resize: vertical;
  font-size: $font-size-xs;
  font-weight: 600;
  color: $color-primary;

  &::placeholder {
    color: rgba($color-primary, 0.3);
  }

  &:focus-visible {
    box-shadow: none;
    outline: 0;
  }
}

.slider-input {
  input {
    width: fit-content;
  }
}
