@keyframes bouncing-loader {
  to {
    opacity: 0.4;
    transform: translateY(-2px);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 5px;
    height: 5px;
    margin: 2px 2px;
    border-radius: 50%;
    background-color: #000000;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
}