@use 'scss/tokens' as *;

.text {
  font-size: $font-size-xs;
}


.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 $spacing-1-5;
    text-align: center;
    color: $color-primary;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    line-height: 1.43;
    font-size: $font-size-xs;
    height: 32px;
    min-width: 32px;
    border: 1px solid $color-stroke;
    border-left: none;

    &.prev {
      border-left: 1px solid $color-stroke;
      border-top-left-radius: $spacing-half;
      border-bottom-left-radius: $spacing-half;
    }

    &.next {
      border-top-right-radius: $spacing-half;
      border-bottom-right-radius: $spacing-half;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: $color-background;
      cursor: pointer;
    }

    &.selected {
      background-color: $color-background;
    }

    &.disabled {
      pointer-events: none;
      color: rgba($color-primary, 0.3);

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.ShortPaginationContainer {
  display: flex;
}

.ShortPaginationInput {
  display: flex;

  input {
    width: 40px;
    height: 24px;
    text-align: center;
    padding: 8px;
  }
}
