@use 'scss/tokens' as *;

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-height: 90vh;
  margin: 5vh auto;
  background-color: $color-white;
  padding: $spacing-5;
  border-radius: 8px;
  overflow: hidden;

  &.bg-gray {
    background-color: $color-background;
  }

  &.modal-no-padding {
    padding: 0;
  }

  &.modal-XS {
    max-width: 320px;
  }

  &.modal-S {
    max-width: 480px;
  }

  &.modal-M {
    max-width: 560px;
  }

  &.modal-MM {
    max-width: 720px;
  }

  &.modal-L {
    max-width: 904px;
  }

  .header {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    font-size: 24px;
  }
}
