@use 'scss/tokens' as *;

.container {
  background-color: $color-gray;
  border-radius: 8px;
  border: 1px solid $color-stroke;
  width: 550px;
  height: 400px;
  position: fixed;
  z-index: 9999;
  max-width: 1200px;
  max-height: 800px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  resize: both;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.drag-drop {
  background-color: $color-background;
  padding: $spacing-2;
  border-radius: 8px 8px 0 0;
  cursor: move;

  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    font-size: 24px;
  }
}

.body {
  background-color: $color-white;
  padding: $spacing-3;
  border-radius: 0 0 8px 8px;
  overflow-y: scroll;
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $spacing-3;
    background-color: $color-white;
  }

  a {
    color: $color-blue;
    font-size: $font-size-sm;

    &:hover {
      text-decoration: underline;
    }
  }
}

.main-header {
  font-weight: 700;
  font-size: $font-size-lg;
  margin: $spacing-3 0;

  &.first {
    margin-top: 0;
  }
}

.sub-header {
  font-weight: 700;
  font-size: $font-size-md;
  margin: $spacing-2 0;
}

.text {
  font-size: $font-size-sm;
  margin: $spacing-half 0;
}

.list {
  margin: $spacing-half 0;
  list-style-position: inside;

  li {
    font-size: $font-size-sm;
    margin: $spacing-half 0;
  }

  .list {
    margin-left: $spacing-1;
  }

  &.linked {
    li:not(.not-linked):hover {
      text-decoration: underline;
    }
  }

  .link:hover {
    text-decoration: underline;
  }

  .button-link {
    list-style-type: none;
  }
}

.button-link {
  color: $color-blue;
}

.alert {
  font-size: $font-size-sm;
  margin: $spacing-2 0;
  padding: $spacing-2;
  background-color: $color-gray;
}

.tag-icon {
  padding: 6px;
  border-radius: 4px;
  margin-right: $spacing-1;
}

.table {
  th {
    text-align: left;
    padding: $spacing-1;
    font-size: $font-size-sm;
  }

  td {
    padding: $spacing-1;
    text-align: left;
    font-size: $font-size-sm;
  }
}
