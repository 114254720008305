@use 'scss/tokens' as *;

.text-label {
  font-weight: 600;
  font-size: 16px;
  color: $color-primary;
  margin-bottom: $spacing-1;
}

.SelectInput {
  display: flex;
  gap: 12px;
  align-items: center;

  .preprocessing-icon {
    background-color: $color-orange;
    border-radius: 2px;
  }

  svg {
    margin: 4px;
  }
}
