@use 'scss/tokens' as *;

.logo-container {
  height: 100%;
  margin-right: $spacing-2;
  position: relative;

  .tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $color-white;
    padding: $spacing-half $spacing-1;
    position: absolute;
    border: 1px solid $color-stroke;
    top: 0;
    left: calc(100% + #{$spacing-1});
    border-radius: 2px;
    white-space: nowrap;
  }
}

.text-container {
  width: 200px;
}

.preprocessing-icon {
  position: absolute;
  background-color: $color-orange;
  border-radius: 2px;

  svg {
    margin: 4px;
  }
}
