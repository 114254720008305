@use "scss/tokens" as *;

.confirm-body {
  margin-top: 16px;
  font-size: $font-size-sm;
}

.confirm-text {
  margin-bottom: 32px;
  font-size: $font-size-sm;
  line-height: 32px;
  width: 472px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  button {
    width: fit-content;
    padding: 8px 48px
  }
}
