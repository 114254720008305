// @import-normalize;
@use "scss/tokens" as *;
@import "./functions";
@import "./utilities";
@import "./lib";
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::after, &::before {
    box-sizing: border-box;
  }
}

a {
  text-decoration: none;
  color: currentColor;

  &:hover {
    text-decoration: underline;
  }
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  background-color: $color-white;
  font-family: 'Raleway', sans-serif;
  font-size: $font-size-md;
  line-height: 24px;
  overflow: hidden;
  color: $color-primary;
}

.container {
  padding: 0 $spacing-3;

  &.fixed {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
}

.disabled-section {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#ffffff, .5);
  }
}

.found-item {
  font-weight: 600;
  display: inline-block;
  background-color: rgba($color-selection, .5);
}

.found-toc-item {
  font-weight: 600;
  display: inline-block;
  background-color: $color-selection;
}

.headerDropdown {
  &.disabled {
    .react-select__control {
      background-color: white;
    }
    .react-select__placeholder {
      opacity: .3;
    }
  }

  .react-select__placeholder {
    opacity: 1;
  }
  .react-select__control {
    box-shadow: unset;
    cursor: pointer;
  }
  &.small {
    .react-select__control {
      min-width: 80px;
    }
  }
  .react-select__menu {
    min-width: 250px;
  }
  &.without-arrow {
    .icon-arrow_drop_down {
      display: none;
    }
  }
}
