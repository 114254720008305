.flex {
    display: flex;
}

.block {
    display: block;
}

.none {
    display: none;
}

.flex-column {
    flex-direction: column;
}
