@use 'scss/tokens' as *;

.checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-stroke;
  border-radius: 2px;
  cursor: pointer;
  color: $color-primary;
  background-color: $color-white;

  &.disabled {
    cursor: auto;
    pointer-events: none;
    background-color: $color-background;
  }

  &.error {
    border-color: $color-red;
  }

  input {
    display: none;
  }
}
