@use 'scss/tokens' as *;

.audioplayer {
  position: relative;
  border: 1px solid $color-stroke;
  border-radius: 4px;
  background-color: $color-background;
  padding: 12px;
}

.progress-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  color: $color-primary;
}

.timing {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $color-primary;
}
