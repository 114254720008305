@use 'scss/tokens/index' as *;

.Container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Text-container {
  overflow: hidden;
}

.Types {
  display: flex;
  margin-top: 8px;
  gap: 4px;
}

.Loader {
  margin: 0 6px;
  margin-top: 6px;
}

.Option-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &.disabled {
    color: $color-gray-1;
    cursor: not-allowed;
  }
}

.Type {
  height: fit-content;
  padding: 0 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: normal;

  &.featured {
    color: $color-brown;
    background-color: $color-yellow-1;
  }
  &.accent {
    color: $color-purple-1;
    background-color: $color-purple;
  }
  &.description,
  &.descriptive {
    color: $color-blue-3;
    background-color: $color-blue-2;
  }
  &.usecase,
  &.use_case {
    color: $color-red-2;
    background-color: $color-red-1;
  }
  &.age {
    color: $color-green-2;
    background-color: $color-green-1;
  }
}
