@use 'scss/tokens' as *;

.action-menu-content {
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  overflow: hidden;
}

.action-menu-item {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  word-wrap: break-word;
  padding: 8px 12px;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &:not(&:last-child) {
    margin-bottom: $spacing-1;
  }

  &.disabled:hover {
    cursor: not-allowed;
    color: $color-gray-1;
  }
}
