@use 'scss/tokens' as *;

.Container {
  display: flex;
  align-items: center;
  gap: $spacing-2;
  padding: $spacing-1-5 $spacing-2;
  background-color: $color-light-sand;
  border-radius: 2px;
  font-weight: 500;
  .Model {
    font-weight: 600;
  }
}