@use 'scss/tokens' as *;

.chapters-header {
  padding: 12px 24px 12px 16px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid $color-stroke;
}

.toc-chapter-group {
  &:not(&:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.toc-chapter-group-parent-element {
  position: relative;
}

.arrow-icon {
  position: absolute;
  left: $spacing-2;
  top: 50%;
  transform: translateY(-50%);
}

.toc-chapters-list {
  overflow: auto;

  background-color: $color-white;
  border: 1px solid $color-stroke;
  border-radius: 2px;
}

.toc-chapter {
  padding: $spacing-2 $spacing-3 $spacing-2 44px;
  cursor: pointer;

  .toc-chapter-title {
    font-size: $font-size-xs;
    font-weight: 600;
    color: $color-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.sub-chapter {
      font-weight: 500;
    }
  }
}

.toc-chapter-actions {
  padding: 3px;
}

.toc-chapter-action {
  border-radius: 2px;
  color: $color-primary;

  &:hover {
    background-color: $color-background;
  }
}
