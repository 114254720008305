@use 'scss/tokens' as *;

$table-gray-color: $color-background;
$table-white-color: $color-white;

.table-container {
  width: 100%;
  border: 1px solid $color-stroke;
  border-radius: 8px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;

  &.hoverable {
    tbody {
      tr:not(.disabled) {
        cursor: pointer;

        &:hover:not(.active) {
          background-color: $table-gray-color;
        }
      }
    }
  }

  td,
  th {
    padding: $spacing-2;
    text-align: left;
    font-size: $font-size-xs;
  }

  th {
    white-space: nowrap; // temp
  }
}

.thead {
  background-color: $table-gray-color;

  th {
    text-align: left;
  }
}

.tbody {
  tr {
    background-color: $table-white-color;

    &:nth-child(even) {
      background-color: rgba($table-gray-color, 0.5);
    }

    &.active {
      background-color: $color-selection;
    }
  }

  .table-empty-message {
    text-align: center;
  }

  .loading {
    text-align: center;
  }
}

.tfoot {
  background-color: $table-gray-color;
}
