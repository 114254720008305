@use 'scss/tokens' as *;

$divider-color: rgba(#000000, 0.1);

.divider {
  color: $divider-color;
  margin: 0;

  &:not(.has-children) {
    border: none;
    border-top: 1px solid currentColor;
  }

  &.has-children {
  }

  &.spacing-0 {
    margin-top: $spacing-0;
    margin-bottom: $spacing-0;
  }

  &.spacing-1 {
    margin-top: $spacing-1;
    margin-bottom: $spacing-1;
  }

  &.spacing-2 {
    margin-top: $spacing-2;
    margin-bottom: $spacing-2;
  }

  &.spacing-3 {
    margin-top: $spacing-3;
    margin-bottom: $spacing-3;
  }

  &.spacing-4 {
    margin-top: $spacing-4;
    margin-bottom: $spacing-4;
  }

  &.spacing-5 {
    margin-top: $spacing-5;
    margin-bottom: $spacing-5;
  }

  &.vertical {
  }
}
