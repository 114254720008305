@use 'scss/tokens' as *;

.result-item {
  font-weight: 500;
  font-size: $font-size-xs;
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;

  &:not(&:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.find-input-wrapper {
  position: relative;

  .arrows {
    position: absolute;
    right: $spacing-1;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: rgba(#000000, 0.5);
  }
}

.find-input {
  input {
    padding: 8px 12px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.findAndApplyInfo {
  margin: 16px 24px 24px!important;
  & > div {
    display: flex!important;
    white-space: nowrap;
  }
}

.foundedResultsDanger{
  background-color: rgba(192, 19, 19, 0.1)!important;
  color: #C01313!important;
  border-radius: 2px;
  font-size: $font-size-xs!important;
  line-height: 24px;
  font-weight: 600;
  padding: 12px 16px!important;
  span {
      margin-left: 30px;
      cursor: pointer;
  }
}


.foundedResultsWarning {
  margin-top: 24px;
  border-radius: 4px;
  padding: 12px 16px;
  & > div {
    display: flex!important;
    white-space: unset;
  }
}

.wrapper {
  height: 312px;
  overflow-y: auto;
}

.arrow {
  transform: rotate(-0.25turn);
  width: 14px;
  height: 11px;
  margin-left: 25px;
  margin-top: 25px
}

.resultsContainer {
  border: 1px solid #D7DAE1;
  border-radius: 2px;
  background-color: #FFFF;
  margin: 0 24px;
}

.showBtn {
  text-decoration: underline;
}
.selectPresetBtn {
  width: 100%;
  background-color: #FFFFFF;
  div {
    width: 100%;
  }
  .selectPresetBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

