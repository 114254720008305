@use 'sass:math';
@use 'scss/tokens' as *;

.stack {
  display: flex;

  .flex {
    display: flex;
  }

  .item {
    flex: 0 1 auto;
    min-width: 0;
    max-width: 100%;

    &.fill {
      flex: 1 1 auto;
    }

    &.shrink-0 {
      flex-shrink: 0;
    }

    &.shrink-1 {
      flex-shrink: 2;
    }

    &.shrink-2 {
      flex-shrink: 3;
    }

    &.shrink-3 {
      flex-shrink: 4;
    }

    &.shrink-4 {
      flex-shrink: 4;
    }

    &.shrink-5 {
      flex-shrink: 5;
    }

    &.grow-0 {
      flex-grow: 0;
    }

    &.grow-1 {
      flex-grow: 1;
    }

    &.grow-2 {
      flex-grow: 2;
    }

    &.grow-3 {
      flex-grow: 3;
    }

    &.grow-4 {
      flex-grow: 4;
    }

    &.grow-5 {
      flex-grow: 5;
    }
  }

  &.direction-row {
    flex-direction: row;
  }

  &.direction-row-reverse {
    flex-direction: row-reverse;
  }

  &.direction-row,
  &.direction-row-reverse {
    &.spacing-0 {
      margin-left: -(math.div($spacing-0, 2));
      margin-right: -(math.div($spacing-0, 2));

      & > .item {
        padding-left: math.div($spacing-0, 2);
        padding-right: math.div($spacing-0, 2);
      }
    }

    &.spacing-1 {
      margin-left: -(math.div($spacing-1, 2));
      margin-right: -(math.div($spacing-1, 2));

      & > .item {
        padding-left: math.div($spacing-1, 2);
        padding-right: math.div($spacing-1, 2);
      }
    }

    &.spacing-2 {
      margin-left: -(math.div($spacing-2, 2));
      margin-right: -(math.div($spacing-2, 2));

      & > .item {
        padding-left: math.div($spacing-2, 2);
        padding-right: math.div($spacing-2, 2);
      }
    }

    &.spacing-3 {
      margin-left: -(math.div($spacing-3, 2));
      margin-right: -(math.div($spacing-3, 2));

      & > .item {
        padding-left: math.div($spacing-3, 2);
        padding-right: math.div($spacing-3, 2);
      }
    }

    &.spacing-4 {
      margin-left: -(math.div($spacing-4, 2));
      margin-right: -(math.div($spacing-4, 2));

      & > .item {
        padding-left: math.div($spacing-4, 2);
        padding-right: math.div($spacing-4, 2);
      }
    }

    &.spacing-5 {
      margin-left: -(math.div($spacing-5, 2));
      margin-right: -(math.div($spacing-5, 2));

      & > .item {
        padding-left: math.div($spacing-5, 2);
        padding-right: math.div($spacing-5, 2);
      }
    }
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-column-reverse {
    flex-direction: column-reverse;
  }

  &.direction-column,
  &.direction-column-reverse {
    &.spacing-0 > * {
      margin-top: $spacing-0;
    }

    &.spacing-1 > * {
      margin-top: $spacing-1;
    }

    &.spacing-2 > * {
      margin-top: $spacing-2;
    }

    &.spacing-3 > * {
      margin-top: $spacing-3;
    }

    &.spacing-4 > * {
      margin-top: $spacing-4;
    }

    &.spacing-5 > * {
      margin-top: $spacing-5;
    }
  }

  &.wrap-nowrap {
    flex-wrap: nowrap;
  }

  &.wrap-wrap {
    flex-wrap: wrap;
  }

  &.wrap-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.jc-flex-start {
    justify-content: flex-start;
  }

  &.jc-flex-end {
    justify-content: flex-end;
  }

  &.jc-space-between {
    justify-content: space-between;
  }

  &.jc-center {
    justify-content: center;
  }

  &.jc-space-around {
    justify-content: space-around;
  }

  &.jc-space-evenly {
    justify-content: space-evenly;
  }

  &.ai-flex-start {
    align-items: flex-start;
  }

  &.ai-flex-end {
    align-items: flex-end;
  }

  &.ai-center {
    align-items: center;
  }

  &.ai-stretch {
    align-items: stretch;
  }

  &.ai-baseline {
    align-items: baseline;
  }

  &.ac-stretch {
    align-content: stretch;
  }

  &.ac-flex-start {
    align-content: flex-start;
  }

  &.ac-flex-end {
    align-content: flex-end;
  }

  &.ac-center {
    align-content: center;
  }

  &.ac-space-between {
    align-content: space-between;
  }

  &.ac-space-around {
    align-content: space-around;
  }
}
