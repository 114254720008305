@use "scss/tokens" as *;

.color-white {
  color: $color-white;
}

.color-error {
  color: $color-red;
}

.bg-white {
  background-color: $color-white;
}

.color-gray {
  color: $color-gray;
}

.color-primary {
  color: $color-primary;
}

.color-primary-opacity {
  color: rgba($color-primary, .5);
}

.color-primary-opacity-i {
  color: rgba($color-primary, .5) !important;
}

.color-primary-i {
  color: $color-primary !important;
}

.color-bg-gray {
  background-color: $color-gray;
}
