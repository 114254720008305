@use 'scss/tokens' as *;

@mixin tooLongPopupTableRow {
  height: 40px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  align-items: center;
  padding: 0 $spacing-2;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  border: 1px solid $color-stroke;
  overflow: hidden;
}

.tableHead {
  @include tooLongPopupTableRow;
  border-bottom: 1px solid $color-stroke;

  font-size: $font-size-xs;
  font-weight: 500;
  color: $color-gray-1;
}

.tableBody {
  padding: $spacing-1 0;
  height: 208px;
  background: white;
  overflow: auto;
}

.tableRow {
  @include tooLongPopupTableRow;
  font-size: $font-size-sm;
  font-weight: 600;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.alert {
  width: 100%;
}
