@use 'scss/tokens' as *;
@use 'scss/mixins/utility' as *;

.tab-group {
  @include list-unset();
  display: flex;
  overflow-x: auto;

  &.tab-group-underline {
    box-shadow: 0 -1px 0 0 $color-stroke inset;
  }
}

.tab-group-item {
  display: flex;
  flex-shrink: 0;
}

.tab {
  all: unset;
  color: rgba($color-primary, 0.3);
  cursor: pointer;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: $spacing-1-5 0;
  margin-right: $spacing-3;
  will-change: color, box-shadow;
  transition: color 0.3s box-shadow 0.3s;

  &.tab-active {
    color: $color-primary;
    box-shadow: 0 -2px 0 $color-primary inset;
  }

  &:hover {
    color: rgba($color-primary, 0.8);
    box-shadow: 0 -2px 0 rgba($color-primary, 0.8) inset;
  }

  &.tab-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
