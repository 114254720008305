@use 'scss/tokens' as *;

.VoiceSettingsSlider {
  &:before, &:active:before {
    background-color: $color-orange;
    opacity: 0.3;
    border-radius: 6px;
    width: var(--seek-before-width-custom);
    left: var(--seek-before-start);
  }

  @mixin thumb {
    z-index: 0;
    visibility: visible;
    margin-top: -3.5px;
    height: 12px;
    width: 4px;
  }

  &::-webkit-slider-thumb {
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    @include thumb;
  }
  &::-webkit-slider-runnable-track {
    border-radius: 6px;
  }
  &::-moz-range-track {
    border-radius: 6px;
  }
  &::-ms-track {
    border-radius: 6px;
  }
}