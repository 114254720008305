@use '../functions/str-replace' as *;

@mixin modifiers($map, $attribute, $prefix: '-', $separator: '-', $base: 'base', $remove: null) {
	@each $key, $value in $map {
		@if ($remove) {
			$key: str-replace($key, $remove, '');
		}

		$important-key: $key + '-i';

		&#{if($key != $base, #{$prefix}#{$key}, '')} {
			@if type-of($value) == 'map' {
				@include modifiers($value, $attribute, $prefix, $separator, $base, $remove);
			}

			@else if type-of($attribute) == 'list' {
				@each $a in $attribute {
					#{$a}: #{$value};
				}
			}

			@else {
				#{$attribute}: #{$value};
			}
		}

		&#{if($key != $base, #{$prefix}#{$important-key}, '')} {
			@if type-of($value) == 'map' {
				@include modifiers($value, $attribute, $prefix, $separator, $base, $remove);
			}

			@else if type-of($attribute) == 'list' {
				@each $a in $attribute {
					#{$a}: #{$value} !important;
				}
			}

			@else {
				#{$attribute}: #{$value} !important;
			}
		}
	}
}

// Wrapper for modifiers mixin to automatically set the map we're using
@mixin spacing-modifiers( $map, $properties ) {
	@include modifiers(
		$map,
		$attribute: $properties
	);
}
