@use "scss/tokens" as *;

.container {
  padding: 32px!important;
  padding-top: 48px!important;
  width: fit-content!important;
}

.confirm-body {
  margin-top: 16px;
  font-size: $font-size-sm;
}

.confirm-text {
  margin-bottom: 32px;
  font-size: $font-size-sm;
  line-height: 32px;
  width: 472px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  button {
    width: fit-content;
    padding: 8px 48px
  }
}
