@keyframes infiniteLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 2px solid #ffffff;
  border-bottom-color: #c1c1c1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: infiniteLoader 3s linear infinite;
  display: inline-block;
}
