@use 'scss/tokens' as *;

.result-item {
  font-weight: 500;
  font-size: $font-size-xs;
  padding: $spacing-2 0;
  margin: 0 $spacing-2;
  cursor: pointer;

  &:not(&:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.find-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;

  .arrows {
    position: absolute;
    right: $spacing-1;
    top: 20px;
    transform: translateY(-50%);
    font-size: 14px;
    color: rgba(#000000, 0.5);
  }
}

.find-input {
  input {
    padding: $spacing-1 100px $spacing-1 $spacing-1-5;
  }
}

.selected {
  background-color: $color-background;
}

.disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: $spacing-3;
  border: 1px solid $color-stroke;
  border-radius: 2px;
  background-color: $color-white;
}

.list-title {
  padding: $spacing-1-5 $spacing-2;
  border-bottom: 1px solid $color-stroke;
}

.list {
  overflow-y: auto;
}

.is-hidden {
  visibility: hidden;
}
