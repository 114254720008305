@use 'scss/tokens' as *;

.textarea {
  width: 100%;
  font-weight: 600;
  font-size: $font-size-xs;
  line-height: 24px;
  color: $color-primary;
  background-color: $color-white;
  border: 0;
  font-family: $font-family-primary;
  box-shadow: 0 0 0 1px $color-stroke inset;
  padding: $spacing-1 $spacing-1-5;
  border-radius: 2px;
  resize: none;
  overflow-y: hidden;

  &::placeholder {
    color: rgba($color-primary, 0.3);
  }

  &:focus {
    outline: 0;
  }
}
