@use 'scss/tokens' as *;

.icon {
  border: none;
  background: none;
  backface-visibility: hidden;
  color: inherit;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  min-height: 16px;
  text-align: center;
  vertical-align: middle;
  width: auto;
  font-size: 0;

  svg {
    vertical-align: bottom;
  }

  &.hoverable:not(:disabled) {
    cursor: pointer;

    &:hover {
      color: $color-orange;
    }
  }
}

button.icon {
  cursor: pointer;
  color: inherit;

  // Later will add box shadow
  &:focus {
    border-radius: 3px;
    outline: 0;
  }

  &:disabled {
    color: $color-primary;
    opacity: 0.3;
    cursor: default;
  }
}
