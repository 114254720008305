@use 'scss/tokens' as *;

.add-chapter-button-wrapper {
  position: relative;
}

.add-chapter-button {
  width: 100%;
  margin-bottom: 20px;
}

.add-chapter-button-tooltip {
  position: absolute;
  z-index: 1000;
  top: calc(100% - 7px);
  margin: 0 auto;
  left: 0;
  right: 0;

  width: 224px;
  padding: $spacing-1 $spacing-1-5;
  background-color: $color-white;
  border: 1px solid $color-stroke;
  border-radius: 2px;
  font-size: $font-size-xs;
}

.make-sub-chapter-checkbox {
  display: flex;
  gap: 14px;
  align-items: center;
  margin: $spacing-2 0;
  cursor: pointer;
  font-size: $font-size-sm;
  line-height: 20px;
}
