@use 'scss/tokens' as *;

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  // height: 100%;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;

  .page-header {
    background-color: $color-white;
    box-shadow: 0 -1px 0 0 $color-stroke inset;
    z-index: 110;
  }

  .page-sidebar {
    flex: 0 0 auto;
    z-index: 100;
    background-color: $color-background;
    width: 360px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      & {
        display: none;
      }
    }

    &.page-sidebar-left {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.page-sidebar-right {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .page-wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .page-inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
  }

  .page-content {
    padding: $spacing-1-5 0;
    min-height: 0;
    flex: 1 1 auto;
  }

  .page-content-scroll {
    overflow-y: scroll;
  }

  .page-footer {
    padding: 0 $spacing-3 $spacing-3 $spacing-3;
    background-color: $color-white;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 100;
  }
}

.page-actions {
  background-color: $color-background;
  box-shadow: 0 -1px 0 0 $color-stroke inset;
}
