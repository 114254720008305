@use "../tokens/index.scss" as *;
@import './react-select.scss';

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}

.ql-container.ql-snow {
    border: none !important;
}

html {
    scroll-behavior: smooth;

}

.quill {
    height: 100%;
}

.ql-editor {
    padding: 12px $spacing-5 !important;
    scroll-behavior: smooth;

    p {
        line-height: 48px;
        font-size: 18px;
    }
}

.rb-tooltip {
    position: absolute;
    transform: translateY(8px);

    & > div {
        width: 312px;
        background-color: $color-background;
        border: 1px solid $color-stroke;
        border-radius: 2px;
        padding: $spacing-2;
        filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
    }

}

.bookmark-icon {
    background-color: rgba($color-primary, .2);
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-flex;
    align-items: flex-end;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    margin-right: $spacing-1;
    vertical-align: middle;

    &:focus {
        outline: none;
    }

    &::before {
        content: "";
        border-top-width: 7px;
        border-left-width: 5px;
        border-right-width: 5px;
        border-bottom-width: 5px;
        border-color: $color-primary;
        border-bottom-color: transparent;
        border-style: solid;
        border-radius: 2px 2px 0 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0)
    }
}

.rb-comment {
    background-color: $color-orange;
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-flex;
    align-items: flex-end;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    margin-right: $spacing-1;
    vertical-align: middle;
    letter-spacing: 20px;

    &::selection {
        background-color: transparent;
    }

    &:focus {
        outline: none;
    }

    &::before {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        background-image: var(--bg-image);
        background-color: #ffffff;
    }
}

.chapter-icon {
    line-height: 24px;
    display: inline-flex;
    align-items: flex-end;

    &::selection {
        background-color: transparent;
    }

    &:focus {
        outline: none;
    }

    &::before {
        position: relative;
        bottom: 2px;
        user-select: none;
        content: attr(data-name);
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-position: 8% 41%;
        background-color: $color-orange;
        color: #ffffff;
        flex-shrink: 0;
        line-height: 24px;
        border-radius: 2px;
        padding: 0 $spacing-1;
        font-size: 12px;
        display: inline-block;
    }

    &.the-first-chapter::before {
        padding: 0 $spacing-1 0 $spacing-3;
    }


    &.sub-chapter::before {
        background-color: $color-blue;
    }
}

.rb-found-text {
    font-weight: bold;
    display: inline;
}

.rb-found-toc-text {
    font-weight: bold;
    display: inline;
}

.rb-found-text-current {
    @extend .rb-found-text;
    background-color: rgba(#FF5C00, .1);
    &:focus {
        outline: none;
    }
}


.rb-toc-found-text-current {
    @extend .rb-found-text;
    background-color: $color-sand;
    &:focus {
        outline: none;
    }
}

.rb-selection {
    display: inline;
    background-color: rgb(183, 211, 248);
    border-radius: 4px;
    padding: 14px 0;
    position: relative;

    &::selection {
        background-color: transparent;
    }
}

.rb-tag-icon {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    padding: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .rb-tag-icon-span {
        mask-size: contain;
        mask-repeat: no-repeat;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
    }
}

.rb-skip-tag, .rb-sentence-tag[data-skip="true"] {
    text-decoration: line-through;
}

// these styles are useful for debugging sentences
// .rb-sentence-tag {
//     border: 1px dashed #FF4EFF;
//     background-color: rgba(188, 211, 245, 0.2);
//     border-radius: 1px;
//     margin-left: 8px;
// }

.rb-ssml-tags {
    display: inline;
    border: 1px dashed $color-primary;
    background-color: rgba($color-selection, .2);
    border-radius: 2px;
    line-height: 24px;
    position: relative;
    padding: 4px;
    margin-right: 2px;
    transition: .3s background-color;
    pointer-events: none; // for capturing pseudo element click

    &:hover {
        background-color: rgba($color-selection, .4);
    }

    &.sentence-tag {
        border: none;
        width: 28px;
        letter-spacing: 16px;
        text-align: center;
        background-color: transparent;
        margin-right: 0;

        &::after {
            margin-left: 0;
            position: absolute;
            bottom: 4px;
            right: 4px;
        }
    }

    &::after {
        display: inline-block;
        content: "";
        width: 20px;
        height: 20px;
        margin-left: 4px;
        padding: 4px;
        vertical-align: sub;
        background-image: var(--bg-image);
        background-color: var(--bg-color);
        border: var(--icon-border);
        border-radius: 2px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        pointer-events: auto; // for capturing pseudo element click
    }

    .rb-ssml-tags {
        border: none;
        padding: 0;
        background-color: transparent;

        &::after {
            right: 1px;
            bottom: 1px;
        }
    }

    .rb-selection {
        padding: 0;
    }
}

.rb-ssml-preset {
    display: inline;
    background-color: rgba($color-selection, .2);
    border: 1px dashed $color-primary;
    border-radius: 2px;
    line-height: 24px;
    padding: 4px;
    transition: .3s background-color;
    pointer-events: none;

    &:hover {
        background-color: rgba($color-selection, .4);
    }

    &::after {
        user-select: none;
        content: attr(data-name);
        background-color: $color-primary;
        color: #ffffff;
        flex-shrink: 0;
        line-height: 24px;
        vertical-align: middle;
        border-radius: 2px;
        padding: 0 6px;
        font-weight: 500;
        margin-left: 6px;
        font-size: 12px;
        display: inline-block;
        position: relative;
        bottom: 2px;
        cursor: pointer;
        pointer-events: auto;
    }
}

.ssml-editor {
    .rb-ssml-tags, .rb-skip-tag, .rb-ssml-preset, .rb-insert-tag {
        color: rgba(#000000, .6);
        border: none;
        display: initial;
        font-weight: inherit;
        border-radius: unset;
        line-height: 48px;
        vertical-align: unset;
    }

    .rb-skip-tag {
        text-decoration: none;
    }

    .sentence-tag {
        //width: 28px;
        letter-spacing: initial;
    }

    .rb-ssml-tags, .rb-skip-tag, .rb-ssml-preset, .rb-insert-tag {
        &::after {
            all: unset;
            content: attr(data-after-text);
            // margin-left: $spacing-1;
        }

        &::before {
            all: unset;
            content: attr(data-before-text);
            // margin-right: $spacing-1;
        }
    }
}

// .rb-selection {
//     background-color: rgb(183, 211, 248);
//     position: absolute;
//     z-index: -1;
//     height: 48px;
//     transform: translateY(-12px);
// }

#highlight-canvas {
    position: absolute;
    z-index: -1;
    bottom: 0;
}

#undo-redo-animation-canvas {
    position: absolute;
    display: none;
    z-index: -1;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 1.5s forwards;
}

