@use 'scss/tokens' as *;

.tag-icon {
  padding: 6px;
  border-radius: 4px;
  margin: 0 16px 0 20px;
}

.tag-attr {
  min-width: 125px !important;
  flex-basis: 125px !important;
  max-width: 224px !important;
  flex-grow: 1!important;
  margin: 20px 0 20px auto
}

.disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.numericAttr {
  max-width: 175px!important;
  margin-bottom: 8px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.pre-processing-button-wrapper {
  padding: 20px 24px;
  box-shadow: 0 -1px 0 0 $color-stroke inset;
}

.pre-processing-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
