@use 'scss/tokens' as *;

.container {
  // Labels Status


  padding: $spacing-half $spacing-1-5;
  border-radius: 3px;
  display: inline-block;
  white-space: nowrap;

  // default color
  color: $label-retake;
  background-color: $label-bg-retake;

  &.done {
    color: $label-done;
    background-color: $label-bg-done;
  }

  &.incomplete {
    color: $color-black;
    background-color: $color-gray;
  }

  &.deactivated {
    color: $color-error;
    background-color: $color-white;
    border: 1px solid $color-error;
  }

  &.declined {
    color: $label-declined;
    background-color: $label-bg-declined;
  }

  &.new-project {
    color: $label-new-project;
    background-color: $label-bg-new-project;
  }

  &.retake {
    color: $label-retake;
    background-color: $label-bg-retake;
  }

  &.in-review {
    color: $label-in-review;
    background-color: $label-bg-in-review;
  }

  &.pending {
    color: $label-pending;
    background-color: $label-bg-pending;
  }

  &.in-progress {
    color: $label-in-progress;
    background-color: $label-bg-in-progress;
  }

  &.assigned {
    color: $label-assigned;
    background-color: $label-bg-pending;
  }
}
