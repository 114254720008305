@use 'scss/tokens' as *;

.preset {
  box-shadow: 0 0 0 1px $color-stroke inset;
  border-radius: 2px;
  margin-bottom: $spacing-1;
  min-height: 40px;
  padding: $spacing-1 12px;
  background-color: $color-white;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }

  .label-name {
    font-weight: 600;
    font-size: $font-size-xs;
  }

  .tag-icon {
    margin-left: 4px;
    border-radius: 2px;
    padding: 4px;
  }

  .open-container {
    margin-top: $spacing-1;
  }

  .tags-description {
    background-color: $color-background;
    padding: $spacing-1;

    .tag-icon {
      margin-left: 0;
    }

    .tag-description:not(:last-child) {
      margin-bottom: $spacing-1;
    }
  }

  .name {
    font-weight: 500;
    font-size: 14px;
  }

  .btn-actions {
    margin-top: $spacing-1;
  }
}

.preset-modal-tags {
  height: 100%;
  background-color: $color-background;
  padding: $spacing-4 $spacing-4 $spacing-2;
  overflow: scroll;
}
