// Helper: !important utility
// Takes a selector with a single declaration and creates a standard
// variation, and an !important variation that ends in `-i`

@mixin utility($class, $property, $value) {
  .#{$class} {
    #{$property}: #{$value};
  }
  .#{$class}-i {
    #{$property}: #{$value} !important;
  }
}

@mixin list-unset {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
}