@use 'scss/tokens' as *;

.alert {
  padding: $spacing-2 $spacing-1;
  font-size: $font-size-xs;
  font-weight: 600;
  border-radius: $spacing-half;

  &.alert-warning {
    background-color: $color-light-sand;
    color: $color-primary;
  }

  &.alert-error {
    background-color: $color-light-red;
    color: $color-dark-blue;
  }
}
