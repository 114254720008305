@use 'scss/tokens' as *;

.flex {
  display: flex;
}

.button {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: $spacing-1 $spacing-2;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  border: 0;
  font-family: $font-family-primary;

  &:disabled {
    cursor: auto;
    pointer-events: none;
  }

  &.btn-small {
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 2px;
  }

  &.btn-primary {
    background-color: $color-primary;
    color: #ffffff;

    &:hover {
      background-color: $color-orange;
    }

    &.btn-subtle {
      background-color: transparent;
      color: $color-primary;

      &:hover {
        svg {
          fill: $color-orange;
        }
      }
    }

    &:disabled {
      color: rgba($color-primary, 0.3);
      background-color: $color-stroke;
    }
  }

  &.btn-secondary {
    background-color: $color-stroke;
    color: $color-primary;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba($color-primary, 0.15),
          rgba($color-stroke, 0.15)
        ),
        $color-stroke;
    }

    &.btn-subtle {
      background-color: transparent;
      color: $color-primary;
    }

    &:disabled {
      color: rgba($color-primary, 0.3);
      background-color: $color-stroke;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    color: $color-light-sand;

    &:hover {
      background: $color-stroke;
      color:$color-red;
    }

    &.btn-inline {
      background-color: transparent;
      color: $color-red;
      border-color: $color-red;
      &:hover {
        background-color: $color-red;
        color: $color-light-sand;
      }
    }

    &.btn-subtle {
      background-color: transparent;
      color: $color-red;
      &:hover {
        text-decoration: underline;
      }
    }

    &:disabled {
      color: rgba($color-red, 0.3);
      background-color: $color-stroke;
    }
  }

  &.btn-full {
    width: -webkit-fill-available;
    display: block;
  }

  &.btn-inline {
    padding: 0 $spacing-1;
    background-color: transparent;
    color: $color-primary;
    font-size: $font-size-sm;
    font-weight: 600;

    &:disabled {
      color: rgba($color-primary, 0.3);
      background-color: transparent;
    }

    &:hover {
      background-color: $color-stroke;
    }
  }

  &.btn-icon {
    padding: 6px;
    line-height: 20px;
    font-size: 0;
  }

  &.btn-large {
    font-size: $font-size-sm;
    padding: $spacing-1-5 $spacing-6;
  }
}
