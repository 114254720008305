$color-black: #000000;
$color-white: #ffffff;
$color-dark-blue: #031E50;
$color-blue: #0073BB;
$color-blue-1: #2A9FD5;
$color-blue-2: #DDE9FF;
$color-blue-3: #263FA9;
$color-orange: #FF5C35;
$color-stroke: #D7DAE1;
$color-background: #F2F3F6;
$color-sand: #FAE1B9;
$color-light-sand: #FEF7ED;
$color-red: #C01313;
$color-light-red: #FFE6E6;
$color-red-1: #FFE5E4;
$color-red-2: #BD342C;
$color-yellow: #EAB325;
$color-yellow-1: #FFFAC2;
$color-green: #49B44E;
$color-green-1: #D7F2E2;
$color-green-2: #006F47;
$color-selection: #BCD3F5;
$color-gray: #E5E5E5;
$color-gray-1: #737C8C;
$color-brown: #945A1C;
$color-purple: #F1E8FD;
$color-purple-1: #6326A2;

$color-primary: $color-dark-blue;

// Colors
$color-success: $color-green;
$color-error: #C01313;
$color-warning: #F8841A;
$color-info: $color-dark-blue;
$color-info-secondary: #0073BB;


// NotificationsItem Colors
$notification-info: $color-info;
$notification-info-secondary: $color-info-secondary;
$notification-error: $color-error;
$notification-warning: $color-warning;
$notification-success: $color-success;

// status label
$label-done: $color-success;
$label-bg-done: rgba($label-done, 0.1);

$label-declined: #930000;
$label-bg-declined: rgba($label-declined, 0.15);

$label-new-project: $color-warning;
$label-bg-new-project: rgba($label-new-project, 0.1);

$label-retake: $color-info;
$label-bg-retake: rgba($label-retake, 0.1);

$label-in-progress: #0046C7;
$label-bg-in-progress: rgba($label-in-progress, .1);

$label-in-review: #851487;
$label-bg-in-review: rgb($label-in-review, .1);

$label-pending: #03828A;
$label-bg-pending: rgba($label-pending, 0.15);

$label-assigned: #757700;
$label-bg-pending: rgba(#A9AC00, 0.15);


$spacing-0: 0px;
$spacing-half: 4px;
$spacing-1: 8px;
$spacing-1-5: 12px;
$spacing-2: 16px;
$spacing-3: 24px;
$spacing-4: 32px;
$spacing-5: 48px;
$spacing-6: 64px;
$spacing-7: 96px;
$spacing-8: 128px;

$spacing-map: (
        "0": ($spacing-0),
        "1": ($spacing-1),
        "1-5": ($spacing-1-5),
        "2": ($spacing-2),
        "3": ($spacing-3),
        "4": ($spacing-4),
        "5": ($spacing-5),
        "6": ($spacing-6),
        "7": ($spacing-7),
        "8": ($spacing-8),
        "half": (4px)
);

$font-size-xl: 32px;
$font-size-lg: 22px;
$font-size-md: 18px;
$font-size-sm: 16px;
$font-size-xs: 14px;
$font-size-xss: 10px;

$font-family-primary: 'Raleway', sans-serif;
