@use 'scss/tokens' as *;

.comment-item {
  padding-left: $spacing-3;
  padding-right: $spacing-3;
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;
  cursor: pointer;

  &:not(&:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .comment-text {
    font-size: $font-size-sm;
    font-weight: 500;
    word-break: break-word;
  }

  .comment-chapter {
    margin-bottom: $spacing-1;
    font-size: $font-size-xs;
    color: rgba($color-primary, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .comment-date {
    font-size: $font-size-xs;
    color: rgba($color-primary, 0.5);
  }
}
