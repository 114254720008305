@use 'scss/tokens' as *;

.checkboxAll {
  background-color: $color-white;
  padding: $spacing-1;
}

.checkboxContainer {
  background-color: $color-white;
  max-height: 280px;
  overflow: auto;
  padding:  $spacing-1;
}


