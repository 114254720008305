.react-select-container, .react-select__menu-portal {

    .react-select__placeholder {
        font-size: $font-size-sm;
        font-weight: 600;
        color: $color-primary;
        opacity: .3;
    }

    .react-select__indicator {
        padding: 0 8px 0 0 ;
        color: $color-primary;
    }

    .react-select__clear-indicator {
        cursor: pointer;
        padding-right: 0;
    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__control {
        border: 0;
        box-shadow: 0 0 0 1px $color-stroke inset;
        border-radius: 2px;
        min-height: 40px;

        &.react-select__control--menu-is-open {
            border-radius: 2px 2px 0 0;
        }
    }

    .react-select__value-container {
        padding: 0 12px;
    }

    .react-select__input-container {
        margin: 0;
        padding: 0;
    }

    .react-select__single-value {
        margin-left: 0;
        margin-right: 0;
        font-size: $font-size-sm;
        font-weight: 600;
        color: $color-primary;
    }

    .react-select__menu {
        margin-top: -1px;
        border-radius: 0 0 2px 2px;
        padding: 4px;
        box-shadow: 0 0 0 1px $color-stroke inset;
    }

    .react-select__option {
        font-size: $font-size-sm;
        font-weight: 600;
        color: $color-primary;
        background-color: transparent;
        cursor: pointer;
        padding: 4px 8px;
        margin-bottom: 4px;

        &:hover {
            background-color: $color-background;
        }
    }

    .react-select__option--is-selected,.react-select__option--is-disabled {
        opacity: 0.5;
        cursor: default;
    }

    &.small {
        .react-select__control {
            min-height: 32px;
        }
    }

    &.large {
        .react-select__control {
            min-height: 48px;
        }

        .react-select__value-container {
            padding: 0 $spacing-2;
        }

        .react-select__single-value {
            font-size: $font-size-sm;
        }

        .react-select__option {
            font-size: $font-size-sm;
        }

        .react-select__indicator {
            padding: 0 16px 0 0 ;
        }
    }

    &.disabled {
        .react-select__indicator {
            color: $color-primary;
            opacity: .3;
        }

        .react-select__single-value {
            color: $color-primary;
            opacity: .3;
        }

        .react-select__control {
            background-color: $color-background;

            .icon-save {
                color: $color-primary;
                opacity: .3;
            }
        }
    }
}
