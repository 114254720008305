@use "scss/tokens" as *;

.font-size-lg {
    font-size: $font-size-lg;
}

.font-size-md {
    font-size: $font-size-md;
}

.font-size-sm {
    font-size: $font-size-sm;
}

.font-size-xs {
    font-size: $font-size-xs;
}

.font-size-xss {
    font-size: $font-size-xss;
}

.font-size-0 {
    font-size: 0;
}
