@use 'scss/tokens' as *;

.input {
  label {
    display: flex;
    flex-direction: column;
  }

  .label {
    font-size: $font-size-sm;
    font-weight: 500;
    margin-bottom: $spacing-1;
  }

  input {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $color-primary;
    background-color: $color-white;
    border: 0;
    font-family: $font-family-primary;
    box-shadow: 0 0 0 1px $color-stroke inset;
    padding: 8px 10px;
    border-radius: 2px;

    &::placeholder {
      color: rgba($color-primary, 0.3);
    }

    &:focus {
      outline: 0;
    }
  }

  &.xs {
    input {
      padding: 0 8px;
      font-size: $font-size-xs;
    }
  }

  &.small {
    input {
      padding: 4px 12px;
      font-size: $font-size-xs;
    }
  }

  &.large {
    input {
      padding: 12px 16px;
      font-size: $font-size-sm;
    }
  }
}
