@use 'scss/tokens' as *;

.EditVoiceSettingsContainer {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  overflow: visible;
}

.Body {
  display: flex;
  align-items: stretch;
  min-height: 440px;
  gap: $spacing-4;
  padding: $spacing-3;

  &>div {
    flex: 1;
  }
}

.Warning {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 440px;
  padding: $spacing-3;
}

.Footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-stroke;
  padding: $spacing-3;
}

.ModelAndVoice {
  display: flex;
  flex-direction: column;
  gap: $spacing-3;
}

.OpenMenuButton:hover {
  background-color: transparent !important;
}

.OpenMenuSettingsIcon {
  margin-right: $spacing-1;
}

.VoiceSettings {
  padding: 0 !important;
  box-shadow: none !important;
}

.VoiceSettingsLabel {
  margin-bottom: -4px;
}