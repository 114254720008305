@use 'scss/tokens' as *;

.Overlay {
  background-color: $color-white;
  box-shadow: 0 0 0 1px $color-stroke inset;
  border-radius: 2px;
}

.Wrapper {
  padding: 12px 16px 12px;
}

.Label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 16px;
  font-size: $font-size-sm;
  font-weight: 600;
}

.Body {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: $spacing-1-5 0;
  padding: 16px;
  background-color: $color-background;
}

.Footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-half;
}

.FooterSpeakerBoost {
  display: flex;
  align-items: center;

  button {
    display: flex;
  }
}

.FooterSpeakerBoostLabel {
  margin: 0 8px 0 12px;
}
