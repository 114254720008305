@use 'scss/tokens' as *;

.Container {
  display: flex;
  align-items: center;
  padding: $spacing-1-5 $spacing-2;
  background-color: $color-light-red;
  border-radius: 2px;
  font-weight: 500;
}
