@use 'scss/tokens' as *;

.Description {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    display: flex;
  }
}

.PopoverMenuContent {
  width: fit-content !important;
  max-width: 16rem;
}

.PopoverItemContent {
  color: $color-primary;

  &:hover {
    background-color: #fff;;
    pointer-events: none !important;
  }
}
