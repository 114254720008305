@use 'scss/tokens' as *;

.Label {
  font-size: $font-size-xs;
}

.Descriptions {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-xs;
  color: $color-gray-1;
}