@use '../mixins/utility' as *;

@include utility('ta-left', 'text-align', 'left');
@include utility('ta-right', 'text-align', 'right');
@include utility('ta-center', 'text-align', 'center');
@include utility('ta-justify', 'text-align', 'justify');

.t-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.t-align-right {
    text-align: right;
}
