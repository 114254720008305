@use 'scss/tokens' as *;

.file-uploader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 0 0 1px $color-stroke inset;
  padding: 12px 16px;
  line-height: 24px;

  &.file-uploader-disabled {
    opacity: .5;
    pointer-events: none;
  }

  input {
    display: none;
  }

  .label {
    display: block;
    color: $color-primary;
    font-size: $font-size-sm;
    cursor: pointer;
    opacity: 0.3;
    &.selected {
      opacity: 1;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
