@use 'scss/tokens' as *;

.create-page {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  margin: 0 auto;

  .elements {
    width: 60%;
    max-width: 504px;
    margin-top: $spacing-7;
  }
}

.buttons {
  margin-top: 40px;
}

.main-header {
  position: relative;
  display: flex;
  font-weight: 700;
  font-size: $font-size-xl;
  line-height: 32px;
  color: $color-primary;
  text-align: center;
  margin-bottom: 40px;

  .main-header-back-button {
    position: absolute;
    padding: 4px;
  }

  .main-header-title {
    margin: 0 auto;
  }
}

.sub-header {
  font-weight: 700;
  font-size: $font-size-lg;
  color: $color-primary;
  text-align: left;
  margin-bottom: $spacing-3;
}

.reviewer-container {
  margin-top: $spacing-6;
}

.add-review {
  margin-top: $spacing-2;
  cursor: pointer;
  i {
    margin-right: $spacing-1;
  }
  font-size: $font-size-sm;
}

.errorMessage {
  color: $color-error;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}

.project-content,
.project-footer {
  padding: 0 !important;
}

.project-footer {
  background-color: $color-gray !important;
}