@use 'scss/tokens' as *;

.bookmark-item {
  padding-left: $spacing-3;
  padding-right: $spacing-3;
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;
  cursor: pointer;

  &:not(&:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .bookmark-title {
    font-size: $font-size-sm;
    font-weight: 600;
    color: $color-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bookmark-chapter {
    margin-top: 4px;
    font-size: $font-size-xs;
    color: rgba($color-primary, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bookmark-title-input {
    display: block;
    width: 100%;
    border: 0;
    font-size: $font-size-xs;
    line-height: 24px;
    padding: 4px 12px 4px 8px;
    font-weight: 600;
    color: $color-primary;

    &:focus {
      outline: 0;
    }
  }
}
